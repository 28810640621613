import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import {
  makeStyles, fade, useTheme, darken,
} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.5rem 0.5rem',
    width: '100%',
    margin: '1rem 0rem',
  },
  cardRoot: {
    position: 'relative',
    boxShadow: (props) => (props.imageColors
      ? `10px 10px 15px ${darken(
        theme.palette.type === 'dark'
          ? props.imageColors.darkMuted
          : props.imageColors.lightMuted,
        theme.palette.type === 'dark'
          ? 0.8 : 0.65
      )}`
      : `10px 10px 15px ${theme.palette.type === 'dark' ? '#000000' : '#666666'}`),
    '&:hover': {
      boxShadow: (props) => (props.imageColors
        ? `10px 10px 30px ${darken(
          theme.palette.type === 'dark' ? props.imageColors.darkMuted : props.imageColors.lightMuted,
          theme.palette.type === 'dark' ? 0.8 : 0.65
        )}`
        : `10px 10px 30px ${theme.palette.type === 'dark' ? '#000000' : '#666666'}`),
      transform: 'translate(-1px, -5px)',
    },
    '&:active': {
      boxShadow: (props) => (props.imageColors
        ? `10px 10px 10px ${darken(
          theme.palette.type === 'dark' ? props.imageColors.darkMuted : props.imageColors.lightMuted,
          theme.palette.type === 'dark' ? 0.8 : 0.65
        )}`
        : `10px 10px 10px ${theme.palette.type === 'dark' ? '#000000' : '#666666'}`),
      transform: 'translate(1px, 2px)',
    },
  },
  cardContent: {
    width: '100%',
    position: 'absolute',
    bottom: '0',
    background: fade('#000', 0.62),
  },
  cardTitle: {
    color: '#ececec',
  },
  cardDescription: {
    color: '#d2d2d2',
  },
}))

export const CreationsCard = ({
  imageObj, imageAlt, imageColors, title, description, date, linkUrl
}) => {
  const classes = useStyles({ imageColors })
  const theme = useTheme()

  return (
    <Card className={classes.cardRoot}>
      <Link to={linkUrl} style={{ textDecoration: 'none', color: 'unset', animation: 'unset' }}>
        <CardActionArea>
          <CardMedia component="div">
            <PreviewCompatibleImage
              imageInfo={{
                image: imageObj,
                alt: imageAlt,
              }}
            />
          </CardMedia>
          <CardContent classes={{ root: classes.cardContent }}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.cardDescription}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  )
}

CreationsCard.propTypes = {
  imageObj: PropTypes.object,
  imageAlt: PropTypes.string,
  imageColors: PropTypes.shape({
    darkVibrant: PropTypes.string,
    darkMuted: PropTypes.string,
    lightMuted: PropTypes.string,
    lightVibrant: PropTypes.string,
    muted: PropTypes.string,
    vibrant: PropTypes.string,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  linkUrl: PropTypes.string,
}

export const CreationsRoll = ({ data, ...rest }) => {
  const classes = useStyles()
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Grid
      container
      spacing={4}
      className={classes.root}
      justify="flex-start"
      {...rest}
    >
      {posts && posts.map(({ node: post }) => (
        post.frontmatter && post.frontmatter.featuredImage && (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            key={post.id}
          >
            <CreationsCard
              imageObj={post.frontmatter.featuredImage}
              imageAlt={`featured image thumbnail for post ${post.frontmatter.title}`}
              imageColors={post.frontmatter.featuredImage && post.frontmatter.featuredImage.colors}
              title={post.frontmatter.title}
              description={post.frontmatter.description}
              date={post.frontmatter.date}
              linkUrl={post.fields.slug}
            />
          </Grid>
        )))}
    </Grid>
  )
}

CreationsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
    query CreationsRollQuery {
      allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "creations-post"}, hidePost: {eq: false}}}) {
        edges {
          node {
            excerpt(pruneLength: 100)
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
              date(formatString: "MMMM DD, YYYY")
              featuredPost
              featuredImage {
                id
                childImageSharp {
                  fluid(maxWidth: 500, quality: 70) {
                    ...GatsbyImageSharpFluid
                  }
                }
                colors {
                  ...GatsbyImageColors
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data, count) => <CreationsRoll data={data} count={count} />}
  />
)
